import { Box, Grid, Link, Typography } from '@material-ui/core';
import React from 'react';
import { PageTextContentType } from '../../../../hooks/useContentMgmt';
import { FormState } from '../../../../hooks/useFormState.types';
import routes from '../../../../routes';
import theme from '../../../../themes/theme';
import EmailTextField from '../../../email-text-field';
import { DropdownMenu } from '../../../pge-plus-common/DropdownMenu';
import { FullDivider } from '../../../pge-plus-common/FullDivider';
import { GridItem } from '../../../pge-plus-common/Grid';
import PhoneTextField from '../../../phone-text-field';
import TextField from '../../../text-field';
import { ConsultationFormModel } from '../types';
import { useDetailsForm } from './useDetailsForm';
import useAuth from '../../../../hooks/useAuth';
import { SectionInfo } from './SectionInfo';

export function OtherContactInfo({
  content,
  form,
}: {
  content: PageTextContentType;
  form: FormState<ConsultationFormModel>;
}) {
  const { relationshipOptions } = useDetailsForm({ content });
  const { isAuthenticated } = useAuth();

  return (
    <>
      {isAuthenticated && (
        <Typography
          variant="body2"
          css={{ marginTop: 30, marginBottom: 20, fontSize: '20px' }}
        >
          {content.get('OTHER_CONTACT_TITLE')}
        </Typography>
      )}
      {!isAuthenticated && (
        <SectionInfo text={content.get('YOUR_CONTACT_INFO')} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            name={'first-name'}
            label={content.get('FIRST_NAME')}
            {...form.props('firstName')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name={'last-name'}
            label={content.get('LAST_NAME')}
            {...form.props('lastName')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <PhoneTextField
            label={content.get('REUSABLE_PHONE')}
            name="phoneNumber"
            {...form.props('phoneNumber')}
            error={Boolean(form.errors.phoneNumber)}
            helperText={form.errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <EmailTextField
            css={{
              [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                marginTop: 'unset',
              },
            }}
            label={content.get('REUSABLE_EMAIL')}
            name="email"
            inputProps={{ minLength: 1, maxLength: 60 }}
            {...form.props('emailAddress')}
          />
        </Grid>
      </Grid>
      {isAuthenticated && (
        <Typography
          variant="body2"
          css={{
            fontSize: '14px',
            lineHeight: '20px',
            marginTop: 5,
            marginBottom: 20,
          }}
        >
          {content.get('REUSABLE_PHONE_DISCLAIMER')}
        </Typography>
      )}
      <GridItem>
        <Link
          variant="body1"
          href={routes.PRIVACY_POLICY}
          target="_blank"
          css={{
            fontSize: '18px',
            fontWeight: 700,
            textDecoration: 'underline',
            textUnderlineOffset: theme.spacing(0.25),
            cursor: 'pointer',
            [theme.breakpoints.down('xs')]: {
              fontSize: 16,
            },
          }}
        >
          {content.get('HOW_WE_USE_YOUR_PERSONAL_INFORMATION')}
        </Link>
      </GridItem>
      {isAuthenticated && (
        <Box mt={2}>
          <DropdownMenu
            name={content.get('RELATIONSHIP_TO_USER')}
            options={relationshipOptions}
            state={form.values.userRelationship || ''}
            setState={e => {
              void form.setValue('userRelationship', e);
            }}
            errorMsg={(form.errors.userRelationship as string) || ''}
          />
        </Box>
      )}
      {isAuthenticated && <FullDivider />}
    </>
  );
}
