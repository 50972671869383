import { Typography } from '@material-ui/core';
import React from 'react';
import theme from '../../../../themes/theme';

export function SectionInfo({ text }: { text?: string }) {
  return (
    <Typography
      css={{
        fontWeight: 700,
        fontFamily: 'Untitled-Sans',
        margin: theme.spacing(0, 0, 3, 0),
        [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
          fontSize: '20px',
        },
      }}
      variant="h4"
    >
      {text}
    </Typography>
  );
}
