import { Container } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import { Details } from '../../../../components/pge-plus/consultation/details';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useConsultation } from '../../../../components/pge-plus/consultation/useConsultation';
import { navigate } from 'gatsby';
import routes from '../../../../routes';

const DetailsPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { t } = useTranslation();
  const {
    isPGEPlusConsultationEnabled,
    isBrowser,
    featureFlagLoading,
  } = useConsultation();

  if (!isPGEPlusConsultationEnabled && isBrowser && !featureFlagLoading) {
    void navigate(routes.ACCOUNT);
  }

  return (
    <>
      <Helmet>
        <title>{t('PGE+ Consultation - Details')}</title>
      </Helmet>
      <Container>
        <Details />
      </Container>
    </>
  );
};

export default DetailsPage;
