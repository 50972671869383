import React from 'react';
import gql from 'not-graphql-tag';
import {
  EvConsultDiscussionTopic,
  HvConsultationService,
  HvConsultDiscussionTopic,
  MutationSubmitEvConsultArgs,
  MutationSubmitHvConsultArgs,
  PreferredDay,
  PreferredTime,
  SubmitEVConsultInput,
  SubmitEvConsultOutput,
  SubmitHVConsultInput,
  SubmitHvConsultOutput,
} from '../../../../__generated__/pgeplus-types';
import apolloClient from '../../../../lib/apolloClient';
import { PageTextContentType } from '../../../../hooks/useContentMgmt';
import { FormState } from '../../../../hooks/useFormState.types';
import Charger from '../../../pge-plus-common/static/charger.svg';
import DuctedHeatPump from '../../../pge-plus-common/static/ducted-heat-pump.svg';
import Allergen from '../../../pge-plus-common/static/Allergen.svg';
import BasicOperations from '../../../pge-plus-common/static/BasicOperations.svg';
import Cooling from '../../../pge-plus-common/static/Cooling.svg';
import SmartCharger from '../../../pge-plus-common/static/Smart-Charger.svg';
import ProductSelection from '../../../pge-plus-common/static/Product-selection.svg';
import Lvl1Lvl2 from '../../../pge-plus-common/static/Level-1-vs-Level-2.svg';
import PanelUpgrade from '../../../pge-plus-common/static/Panel-upgrade.svg';
import TOD from '../../../pge-plus-common/static/TOD.svg';
import Installation from '../../../pge-plus-common/static/Installation.svg';
import EnergySavings from '../../../pge-plus-common/static/EnergySavings.svg';
import Heating from '../../../pge-plus-common/static/Heating.svg';
import HeatingCooling from '../../../pge-plus-common/static/HeatingCooling.svg';
import HowDoesPGEWork from '../../../pge-plus-common/static/HowDoesPGEWork.svg';
import Humidity from '../../../pge-plus-common/static/Humidity.svg';
import Other from '../../../pge-plus-common/static/Other.svg';
import PTR from '../../../pge-plus-common/static/PTR.svg';
import SmartThermostat from '../../../pge-plus-common/static/SmartThermostat.svg';
import SoundLevel from '../../../pge-plus-common/static/SoundLevel.svg';
import { ConsultationFormModel, Product } from '../types';
import { C2MPreferredLanguages } from '../../../../util/languageUtil';
import useAuth from '../../../../hooks/useAuth';
import { displayPhoneNumberFormat } from '../../../../util/format';
import { Typography } from '@material-ui/core';

const HvConsultDetailsSubmitMutation = gql`
  mutation SubmitHVConsult($input: SubmitHVConsultInput!) {
    submitHVConsult(input: $input) {
      isSuccess
    }
  }
`;

const EvConsultDetailsSubmitMutation = gql`
  mutation SubmitEVConsult($input: SubmitEVConsultInput!) {
    submitEVConsult(input: $input) {
      isSuccess
    }
  }
`;

export function useDetailsForm({ content }: { content: PageTextContentType }) {
  const { isAuthenticated } = useAuth();

  const productOptions: {
    label: React.ReactNode;
    value: Product;
    img: React.ReactNode;
  }[] = [
    {
      label: (
        <Typography>
          {content.get('RESIDENTIAL')}
          <br />
          {content.get('EV_CHARGING')}
        </Typography>
      ),
      value: 'ev',
      img: <Charger />,
    },
    {
      label: (
        <Typography>
          {content.get('RESIDENTIAL')}
          <br />
          {content.get('HVAC')}
        </Typography>
      ),
      value: 'hvac',
      img: <DuctedHeatPump />,
    },
  ];

  const mainContactOptions: { label: string; value: boolean }[] = [
    {
      label: content.get('REUSABLE_YES') || '',
      value: true,
    },
    {
      label: content.get('REUSABLE_NO') || '',
      value: false,
    },
  ];

  const relationshipOptions = [
    { label: content.get('RELATIONSHIP_PARENT'), value: 'parent' },
    { label: content.get('RELATIONSHIP_SIBLING'), value: 'sibling' },
    { label: content.get('RELATIONSHIP_SPOUSE'), value: 'spouse' },
    { label: content.get('RELATIONSHIP_PARTNER'), value: 'partner' },
    { label: content.get('RELATIONSHIP_FRIEND'), value: 'friend' },
    { label: content.get('RELATIONSHIP_OTHER'), value: 'other' },
  ];

  const dayOfTheWeekOptions = [
    {
      label: content.get('REUSABLE_DAY_MONDAY') || '',
      value: PreferredDay.Monday,
    },
    {
      label: content.get('REUSABLE_DAY_TUESDAY') || '',
      value: PreferredDay.Tuesday,
    },
    {
      label: content.get('REUSABLE_DAY_WEDNESDAY') || '',
      value: PreferredDay.Wednesday,
    },
    {
      label: content.get('REUSABLE_DAY_THURSDAY') || '',
      value: PreferredDay.Thursday,
    },
    {
      label: content.get('REUSABLE_DAY_FRIDAY') || '',
      value: PreferredDay.Friday,
    },
    {
      label: content.get('NO_PREFERENCE') || '',
      value: PreferredDay.NoPreference,
    },
  ];

  const timeOfTheDayOptions = [
    {
      label: `${content.get('TIME_SLOT_1')}`,
      value: PreferredTime.Morning,
    },
    {
      label: `${content.get('TIME_SLOT_2')}`,
      value: PreferredTime.Afternoon,
    },
    {
      label: `${content.get('TIME_SLOT_3')}`,
      value: PreferredTime.Evening,
    },
    {
      label: `${content.get('NO_PREFERENCE')}`,
      value: PreferredTime.NoPreference,
    },
  ];

  const servicesOptions: {
    label: string;
    value: HvConsultationService;
    img: React.ReactNode;
  }[] = [
    {
      label: content.get('HEATING') || '',
      value: HvConsultationService.Heating,
      img: <Heating />,
    },
    {
      label: content.get('COOLING') || '',
      value: HvConsultationService.Cooling,
      img: <Cooling />,
    },
    {
      label: content.get('HEATING_AND_COOLING') || '',
      value: HvConsultationService.Both,
      img: <HeatingCooling />,
    },
  ];

  const hvacDiscussionOptions: {
    label: string;
    value: HvConsultDiscussionTopic;
    img: React.ReactNode;
  }[] = [
    {
      label: content.get('HOW_DOES_PGE_PLUS_WORK') || '',
      value: HvConsultDiscussionTopic.PgePlus,
      img: <HowDoesPGEWork />,
    },
    {
      label: content.get('SMART_THERMOSTAT_PROGRAM') || '',
      value: HvConsultDiscussionTopic.SmartThermostatProgram,
      img: <SmartThermostat />,
    },
    {
      label: content.get('PEAK_TIME_REBATES') || '',
      value: HvConsultDiscussionTopic.PeakTimeRebates,
      img: <PTR />,
    },
    {
      label: content.get('ENERGY_SAVINGS') || '',
      value: HvConsultDiscussionTopic.EnergySavings,
      img: <EnergySavings />,
    },
    {
      label: content.get('SOUND_LEVEL') || '',
      value: HvConsultDiscussionTopic.SoundLevel,
      img: <SoundLevel />,
    },
    {
      label: content.get('ALLERGEN_AND_VIRUS_FILTRATION') || '',
      value: HvConsultDiscussionTopic.AllergenAndVirusFiltration,
      img: <Allergen />,
    },
    {
      label: content.get('HUMIDITY_CONTROL') || '',
      value: HvConsultDiscussionTopic.HumidityControl,
      img: <Humidity />,
    },
    {
      label: content.get('BASIC_OPERATION') || '',
      value: HvConsultDiscussionTopic.BasicOperation,
      img: <BasicOperations />,
    },
    {
      label: content.get('OTHER') || '',
      value: HvConsultDiscussionTopic.Other,
      img: <Other />,
    },
  ];

  const evDiscussionOptions: {
    label: string;
    value: EvConsultDiscussionTopic;
    img: React.ReactNode;
  }[] = [
    {
      label: content.get('HOW_DOES_PGE_PLUS_WORK') || '',
      value: EvConsultDiscussionTopic.PgePlus,
      img: <HowDoesPGEWork />,
    },
    {
      label: content.get('SMART_CHARGING_PROGRAM') || '',
      value: EvConsultDiscussionTopic.SmartChargingProgram,
      img: <SmartCharger />,
    },
    {
      label: content.get('TIME_OF_DAY') || '',
      value: EvConsultDiscussionTopic.TimeOfDay,
      img: <TOD />,
    },
    {
      label: content.get('LVL_1_LVL_2') || '',
      value: EvConsultDiscussionTopic.LevelOneVsLevelTwo,
      img: <Lvl1Lvl2 />,
    },
    {
      label: content.get('PRODUCT_SELECTION') || '',
      value: EvConsultDiscussionTopic.ProductSelection,
      img: <ProductSelection />,
    },
    {
      label: content.get('INSTALLATION') || '',
      value: EvConsultDiscussionTopic.Installation,
      img: <Installation />,
    },
    {
      label: content.get('PANEL_UPGRADE') || '',
      value: EvConsultDiscussionTopic.PanelUpgrade,
      img: <PanelUpgrade />,
    },
    {
      label: content.get('OTHER') || '',
      value: EvConsultDiscussionTopic.Other,
      img: <Other />,
    },
  ];

  const languagePreferenceOptions = Object.values(C2MPreferredLanguages)
    .filter(language => language !== 'OTH')
    .map(language => ({ label: content.get(language), value: language }));

  function isSubmitDisabled(form: FormState<ConsultationFormModel>) {
    const hasErrors = Object.keys(form.errors).length !== 0;

    if (hasErrors) {
      return true;
    }

    const values = { ...form.values };
    delete values['associationWithAddress'];

    if (
      isAuthenticated &&
      (form.values.isMainContact === true ||
        form.values.isMainContact === undefined)
    ) {
      delete values['emailAddress'];
      delete values['firstName'];
      delete values['lastName'];
      delete values['middleName'];
      delete values['phoneNumber'];
      delete values['userRelationship'];
    }

    if (isAuthenticated && form.values.isMainContact === false) {
      delete values['middleName'];
    }

    if (!isAuthenticated) {
      delete values['middleName'];
      delete values['userRelationship'];
      delete values['isMainContact'];
    }

    if (form.values.product === 'hvac') {
      delete values['evDiscussion'];
      if (!values.hvacDiscussion?.includes(HvConsultDiscussionTopic.Other)) {
        delete values['otherDiscussion'];
      }
    }

    if (form.values.product === 'ev') {
      delete values['service'];
      delete values['hvacDiscussion'];

      if (!values.evDiscussion?.includes(EvConsultDiscussionTopic.Other)) {
        delete values['otherDiscussion'];
      }
    }

    return Object.values(values).some(
      value =>
        value === '' ||
        value === undefined ||
        (Array.isArray(value) ? value?.length === 0 : false),
    );
  }

  function convertFormStateToHvConsultInput(
    accountId: string,
    encryptedPersonId: string,
    encryptedPremiseId: string,
    formData: ConsultationFormModel,
  ): SubmitHVConsultInput {
    const dataToSubmit: SubmitHVConsultInput = {
      accountId: accountId,
      consultationService: formData.service!,
      discussionTopics: formData.hvacDiscussion!,
      personId: encryptedPersonId,
      preferredDay: formData.preferredDay,
      preferredLanguage: formData.preferredLanguage!,
      preferredTime: formData.preferredTimeSlot,
      premiseId: encryptedPremiseId,
    };

    if (formData.hvacDiscussion?.includes(HvConsultDiscussionTopic.Other)) {
      dataToSubmit['otherDiscussion'] = formData.otherDiscussion;
    }

    if (isAuthenticated) {
      dataToSubmit['isMainContact'] = formData.isMainContact;
    }

    if (!formData.isMainContact || !isAuthenticated) {
      dataToSubmit.otherContactInfo = {
        email: formData.emailAddress || '',
        firstName: formData.firstName || '',
        lastName: formData.lastName || '',
        phoneNumber: displayPhoneNumberFormat(formData.phoneNumber || ''),
      };
      if (!formData.isMainContact) {
        dataToSubmit.otherContactInfo.relationship = formData.userRelationship;
      }
    }

    return dataToSubmit;
  }

  function submitHvConsultationDetails(
    accountId: string,
    encryptedPersonId: string,
    encryptedPremiseId: string,
    data: ConsultationFormModel,
  ) {
    const input: SubmitHVConsultInput = convertFormStateToHvConsultInput(
      accountId,
      encryptedPersonId,
      encryptedPremiseId,
      data,
    );
    return apolloClient.mutate<
      { submitHVConsult: SubmitHvConsultOutput },
      MutationSubmitHvConsultArgs
    >({
      mutation: HvConsultDetailsSubmitMutation,
      variables: { input },
      errorPolicy: 'all',
      context: { pgePlus: true },
    });
  }

  function convertFormStateToEvConsultInput(
    accountId: string,
    encryptedPersonId: string,
    encryptedPremiseId: string,
    formData: ConsultationFormModel,
  ): SubmitEVConsultInput {
    const dataToSubmit: SubmitEVConsultInput = {
      accountId: accountId,
      discussionTopics: formData.evDiscussion!,
      personId: encryptedPersonId,
      preferredDay: formData.preferredDay,
      preferredLanguage: formData.preferredLanguage!,
      preferredTime: formData.preferredTimeSlot,
      premiseId: encryptedPremiseId,
    };

    if (formData.evDiscussion?.includes(EvConsultDiscussionTopic.Other)) {
      dataToSubmit['otherDiscussion'] = formData.otherDiscussion;
    }

    if (isAuthenticated) {
      dataToSubmit['isMainContact'] = formData.isMainContact;
    }

    if (!formData.isMainContact || !isAuthenticated) {
      dataToSubmit.otherContactInfo = {
        email: formData.emailAddress || '',
        firstName: formData.firstName || '',
        lastName: formData.lastName || '',
        phoneNumber: displayPhoneNumberFormat(formData.phoneNumber || ''),
      };
      if (!formData.isMainContact) {
        dataToSubmit.otherContactInfo.relationship = formData.userRelationship;
      }
    }

    return dataToSubmit;
  }

  function submitEvConsultationDetails(
    accountId: string,
    encryptedPersonId: string,
    encryptedPremiseId: string,
    data: ConsultationFormModel,
  ) {
    const input: SubmitEVConsultInput = convertFormStateToEvConsultInput(
      accountId,
      encryptedPersonId,
      encryptedPremiseId,
      data,
    );
    return apolloClient.mutate<
      { submitEVConsult: SubmitEvConsultOutput },
      MutationSubmitEvConsultArgs
    >({
      mutation: EvConsultDetailsSubmitMutation,
      variables: { input },
      errorPolicy: 'all',
      context: { pgePlus: true },
    });
  }

  return {
    productOptions,
    mainContactOptions,
    relationshipOptions,
    dayOfTheWeekOptions,
    timeOfTheDayOptions,
    servicesOptions,
    hvacDiscussionOptions,
    evDiscussionOptions,
    languagePreferenceOptions,
    isSubmitDisabled,
    submitHvConsultationDetails,
    submitEvConsultationDetails,
  };
}
